import { PagedSearchModel } from '../types';
import { updatePagedSearchCacheEntry } from './updatePagedSearchCacheEntry';

export function updatePagedSearchError<TData>(error: any, searchKey: string, cache: { [key: string]: PagedSearchModel<TData> }) {
  return cache[searchKey]
    ? updatePagedSearchCacheEntry(
        {
          ...cache[searchKey],
          error,
        },
        cache,
      )
    : { _searchCache: cache };
}
