import { Directive, inject, input, TemplateRef } from '@angular/core';
import { CarouselItemTemplateContext } from '../types/CarouselItemTemplateContext';

@Directive({
  selector: '[carouselItem]',
  standalone: true,
})
export class CarouselItemDirective<TData> {
  public readonly template = inject(TemplateRef<CarouselItemTemplateContext<TData>>);

  static ngTemplateContextGuard<T>(_: CarouselItemDirective<T>, ctx: any): ctx is CarouselItemTemplateContext<T> {
    return true;
  }
}
