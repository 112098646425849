import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ideal-error-card',
  standalone: true,
  imports: [],
  templateUrl: './error-card.component.html',
  styleUrl: './error-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorCardComponent {}
