@let err = error();

<ideal-error-card>
  <ideal-error-title>{{ err.title }}</ideal-error-title>
  <ideal-error-message class="pad-bottom">Access to {{ err.url }} has been denied.</ideal-error-message>
  <ideal-error-message>{{ err.message }}</ideal-error-message>
  @if (err.error) {
    <ideal-error-json [error]="err.error" />
  }
</ideal-error-card>
