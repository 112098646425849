import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { JsonPipe, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  inject,
  input,
  output,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { ElementResizeDirective, MeasureComponent } from 'common-util';

import { outputFromObservable, toObservable } from '@angular/core/rxjs-interop';
import { LoadingPlaceholderComponent } from 'common-ui';
import { GridCellDirective } from '../cells';
import { GridCellInfo } from '../types/GridCellInfo';

import { GridRowInfo } from '../types/GridRowInfo';
import {
  FixedGroupingStrategy,
  FixedGroupingStrategyFn,
  GroupingStrategy,
  isFixedGroupingStrategy,
  MaxItemFitGroupingStrategy,
  MaxItemSizeGroupingStrategy,
  MaxItemsPerGroupStrategy,
} from '../../lists/types/GroupLayoutStrategy';

const measureRowInfo: GridRowInfo = {
  index: 0,
  count: 1,
  first: true,
  last: true,
  even: false,
  odd: true,
};

@Component({
  selector: 'ideal-infinite-grid-layout',
  standalone: true,
  imports: [
    ElementResizeDirective,
    NgTemplateOutlet,
    ScrollingModule,
    MeasureComponent,
    CdkScrollableModule,
    LoadingPlaceholderComponent,
    JsonPipe,
  ],
  templateUrl: './infinite-grid-layout.component.html',
  styleUrl: './infinite-grid-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{ directive: ElementResizeDirective, outputs: ['sizeChange'] }],
  host: {
    '[style.--items-per-row]': 'itemsPerRow()',
    '[style.--item-height]': 'itemHeight() + "px"',
    '[style.--item-size]': 'itemSize() + "px"',
    '[style.--grid-size]': 'measuredContainer() + "px"',
  },
})
export class InfiniteGridLayoutComponent<TData> {
  private readonly _resizeDirective = inject(ElementResizeDirective, { self: true });
  private readonly _cellTemplateDirective = contentChild.required(GridCellDirective, { descendants: true });
  private readonly _measure = viewChild.required<MeasureComponent>(MeasureComponent);

  public readonly GridColumnStrategies = {
    MaxItemsPerRow: MaxItemsPerGroupStrategy,
    MaxItemSize: MaxItemSizeGroupingStrategy,
    MaxItemFit: MaxItemFitGroupingStrategy,
    FixedColumns: FixedGroupingStrategy,
  } as const;

  public readonly noRowsTemplate = contentChild<TemplateRef<void>>('noRowsDef');
  public readonly appendOnly = input<boolean, unknown>(false, { transform: booleanAttribute });

  public readonly data = input.required<TData[]>();
  public readonly reachedEnd = output<void>();

  public readonly layoutStrategy = input<
    GroupingStrategy,
    GroupingStrategy | 'maxItemsPerRow' | 'maxItemSize' | 'maxItemFit' | number | undefined
  >(this.GridColumnStrategies.MaxItemsPerRow, {
    transform: (v) => {
      switch (v) {
        case 'maxItemsPerRow':
          return this.GridColumnStrategies.MaxItemsPerRow;
        case 'maxItemSize':
          return this.GridColumnStrategies.MaxItemSize;
        case 'maxItemFit':
          return this.GridColumnStrategies.MaxItemFit;
        case undefined:
          return this.GridColumnStrategies.MaxItemsPerRow;
        default:
          if (typeof v === 'number') {
            return this.GridColumnStrategies.FixedColumns(v);
          }
          return v;
      }
    },
  });

  protected readonly isFixedColumns = computed(() => isFixedGroupingStrategy(this.layoutStrategy()));
  protected readonly fixedColumnWidthStyle = computed(() => {
    if (!this.isFixedColumns()) return null;

    const measuredContainer = this.measuredContainer();
    const measuredGap = this.measuredGaps().width;
    const strategy = this.layoutStrategy() as FixedGroupingStrategyFn;
    const cols = strategy(measuredContainer, measuredGap);

    const totalGap = measuredGap * (cols - 1);
    return `calc((100% - ${totalGap}px) / ${cols})`;
  });

  protected readonly cellTemplate = computed(() => this._cellTemplateDirective().template);
  protected readonly firstItem = computed(() => this.data()?.[0]);

  protected readonly measuredMinSize = computed(() => this._measure().minSize());
  protected readonly measuredMaxSize = computed(() => this._measure().maxSize());
  protected readonly measuredGaps = computed(() => this._measure().gapSize());
  protected readonly measuredContainer = computed(() => this._resizeDirective.width());

  protected readonly itemsPerRow = computed(() => {
    const measuredContainer = this.measuredContainer();
    if (measuredContainer === 0) {
      return 0;
    }
    const layoutStrategy = this.layoutStrategy();
    return layoutStrategy(measuredContainer, this.measuredMinSize().width, this.measuredMaxSize().width, this.measuredGaps().width);
  });

  public readonly itemsPerRowChange = outputFromObservable(toObservable(this.itemsPerRow));

  protected readonly itemHeight = computed(() =>
    this.isFixedColumns() ? this.measuredMaxSize().height : Math.max(this.measuredMinSize().height, this.measuredMaxSize().height),
  );
  public readonly itemHeightChange = outputFromObservable(toObservable(this.itemHeight));

  protected readonly itemSize = computed(() => this.itemHeight() + this.measuredGaps().height);

  protected readonly dataRows = computed(() => {
    const itemsPerRow = this.itemsPerRow();
    if (itemsPerRow === 0) {
      return [];
    }
    const list = this.data();
    if (itemsPerRow === 1) {
      return list.map((item) => [item]);
    }

    const rows = Array.from({ length: Math.ceil(list.length / itemsPerRow) }).map((_, i) =>
      list.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow),
    );
    return rows;
  });

  private _cellContexts = new Map<
    TData,
    GridCellInfo & {
      $implicit: TData;
    }
  >();

  protected makeCellContext(
    cell: TData,
    rowInfo: GridRowInfo = measureRowInfo,
    colInfo: GridRowInfo = measureRowInfo,
  ): {
    $implicit: TData;
  } & GridCellInfo {
    if (this._cellContexts.has(cell)) {
      return this._cellContexts.get(cell)!;
    }
    const index = rowInfo.index * colInfo.count + colInfo.index;

    const context = {
      $implicit: cell,
      count: this.data().length,
      index,
      first: index === 0,
      last: index === this.data().length - 1,
      even: index % 2 === 0,
      odd: index % 2 !== 0,
      colCount: colInfo.count,
      colIndex: colInfo.index,
      colFirst: colInfo.first,
      colLast: colInfo.last,
      colEven: colInfo.even,
      colOdd: colInfo.odd,
      rowCount: rowInfo.count,
      rowIndex: rowInfo.index,
      rowFirst: rowInfo.first,
      rowLast: rowInfo.last,
      rowEven: rowInfo.even,
      rowOdd: rowInfo.odd,
    };
    this._cellContexts.set(cell, context);
    return context;
  }

  protected indexTrackFn(index: number, _: any): number {
    return index;
  }
}
