import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MarkdownDirective } from 'common-ui';
import stringify from 'json-stringify-safe';
@Component({
  selector: 'ideal-error-json',
  standalone: true,
  imports: [MarkdownDirective],
  templateUrl: './error-json.component.html',
  styleUrl: './error-json.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorJsonComponent {
  public label = input<string>('Details');
  public error = input.required<any>();
  protected json = computed(() => {
    try {
      const json = stringify(this.error(), null, 2);
      return `\`\`\`json\n${json}\n\`\`\``;
    } catch (e) {
      return '' + this.error();
    }
  });
}
