@let err = error();
@let traceId = err.traceId;
@let errorDescriptionUrl = err.errorDescriptionUrl;

<ideal-error-card>
  <ideal-error-title>{{ err.title }}</ideal-error-title>
  <!-- prettier-ignore -->
  <ideal-error-message class="pad-bottom">{{err.message}}</ideal-error-message>
  @if (traceId) {
    <ideal-error-message> Trace Id: {{ traceId }} </ideal-error-message>
  }
  @if (errorDescriptionUrl) {
    <ideal-error-message> Description: {{ errorDescriptionUrl }} </ideal-error-message>
  }
  <ideal-error-json [error]="err"></ideal-error-json>
</ideal-error-card>
