import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'ideal-error-cause',
  standalone: true,
  imports: [],
  templateUrl: './error-cause.component.html',
  styleUrl: './error-cause.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorCauseComponent {
  public label = input<string>('This was caused by the following:');
}
