<ideal-measure
  [template]="itemTemplate()"
  [context]="makeItemContext(firstItem())"
  [style.width]="fixedColumnWidthStyle() ?? '100%'"
></ideal-measure>

<div scroll-container cdkScrollable (mousedown)="onDragStart($event)">
  <div page-list-container>
    <div page></div>
    @for (page of dataPages(); track $index; let pageIndex = $index) {
      <div page #pageContainer>
        @for (item of page; track $index; let itemIndex = $index) {
          <div load-ph-container>
            <ideal-loading-placeholder [loading]="item === null"></ideal-loading-placeholder>
          </div>
          <div item [class.loading]="item === null">
            @let idx = pageIndex * itemsPerPage() + itemIndex;
            <ng-container
              *ngTemplateOutlet="
                itemTemplate();
                context: makeItemContext(item, {
                  index: idx,
                  first: idx === 0,
                  last: idx === data().length - 1,
                  even: idx % 2 === 0,
                  odd: idx % 2 !== 0,
                })
              "
            ></ng-container>
          </div>
        }
      </div>
    }
    <div page></div>
  </div>
</div>
