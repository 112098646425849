import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'section',
  standalone: true,
  imports: [],
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  public readonly title = input('');
}
