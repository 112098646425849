export type MeasuredGroupingStrategyFn = (containerWidth: number, minWidth: number, maxWidth: number, gap: number) => number;

const measuredGroupingStrategy = Symbol('MeasuredGroupingStrategy');
export type MeasuredGroupingStrategy = MeasuredGroupingStrategyFn & { [measuredGroupingStrategy]: true };

export function isMeasuredGroupingStrategy(value: any): value is MeasuredGroupingStrategy {
  return typeof value === 'function' && measuredGroupingStrategy in value;
}

export function createMeasuredGroupingStrategy(strategy: MeasuredGroupingStrategyFn): MeasuredGroupingStrategy {
  return Object.assign(strategy, { [measuredGroupingStrategy]: true as true });
}

export type FixedGroupingStrategyFn = (containerWidth: number, gap: number) => number;
const fixedGroupingStrategy = Symbol('FixedGroupingStrategy');
export type FixedGroupingStrategy = FixedGroupingStrategyFn & { [fixedGroupingStrategy]: true };

export function isFixedGroupingStrategy(value: any): value is FixedGroupingStrategy {
  return typeof value === 'function' && fixedGroupingStrategy in value;
}

export function createFixedGroupingStrategy(strategy: FixedGroupingStrategyFn): FixedGroupingStrategy {
  return Object.assign(strategy, { [fixedGroupingStrategy]: true as true });
}

export type GroupingStrategy = MeasuredGroupingStrategy | FixedGroupingStrategy;

export const MaxItemsPerGroupStrategy: GroupingStrategy = createMeasuredGroupingStrategy(
  (containerWidth: number, minWidth: number, maxWidth: number, gap: number) => {
    const availableWidth = containerWidth + gap;
    let itemWidth = minWidth + gap;
    let itemsPerRow = Math.floor(availableWidth / itemWidth);
    return itemsPerRow;
  },
);

export const MaxItemSizeGroupingStrategy: GroupingStrategy = createMeasuredGroupingStrategy(
  (containerWidth: number, minWidth: number, maxWidth: number, gap: number) => {
    const availableWidth = containerWidth + gap;
    let itemWidth = maxWidth + gap;
    let itemsPerRow = Math.floor(availableWidth / itemWidth);
    return itemsPerRow;
  },
);

export const MaxItemFitGroupingStrategy: GroupingStrategy = createMeasuredGroupingStrategy(
  (containerWidth: number, minWidth: number, maxWidth: number, gap: number) => {
    const availableWidth = containerWidth + gap;
    let itemWidth = maxWidth + gap;
    let itemsPerRow = Math.floor(availableWidth / itemWidth);
    const usedWidth = itemsPerRow * (maxWidth + gap);

    if (usedWidth < availableWidth) {
      const oneMoreWidth = availableWidth / (itemsPerRow + 1);
      if (oneMoreWidth >= minWidth + gap) {
        itemsPerRow++;
      }
    }

    return itemsPerRow;
  },
);

export const FixedGroupingStrategy = (columns: number): GroupingStrategy => createFixedGroupingStrategy(() => columns);
