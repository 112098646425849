import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ideal-error-title',
  standalone: true,
  imports: [MatIconModule],
  template: `<mat-icon>error</mat-icon><ng-content />`,
  styleUrl: './error-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorTitleComponent {}
