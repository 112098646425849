import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PermissionErrorDetails } from 'error-data';
import { ErrorCardComponent, ErrorJsonComponent, ErrorMessageComponent, ErrorTitleComponent } from '../../components';
import { ErrorHandlerBaseComponent } from '../error-handler-base.component';

@Component({
  selector: 'ideal-permission-error-handler',
  standalone: true,
  imports: [ErrorCardComponent, ErrorTitleComponent, ErrorMessageComponent, ErrorJsonComponent],
  templateUrl: './permission-error-handler.component.html',
  styleUrls: ['../error-handler-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionErrorHandlerComponent extends ErrorHandlerBaseComponent<PermissionErrorDetails> {}
