export function serializeFilter<TFilter>(filter: TFilter | undefined) {
  if (!filter) {
    return '';
  }
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(filter)) {
    if (value !== undefined && value !== null) {
      params.set(key, value.toString());
    }
  }
  return params.toString();
}
