import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorCardComponent, ErrorJsonComponent, ErrorMessageComponent, ErrorTitleComponent } from '../../components';
import { BasicErrorDetails } from 'error-data';
import { ErrorHandlerBaseComponent } from '../error-handler-base.component';

@Component({
  selector: 'ideal-basic-error-handler',
  standalone: true,
  imports: [ErrorCardComponent, ErrorTitleComponent, ErrorMessageComponent, ErrorJsonComponent],
  templateUrl: './basic-error-handler.component.html',
  styleUrls: ['../error-handler-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicErrorHandlerComponent extends ErrorHandlerBaseComponent<BasicErrorDetails> {}
