import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, untracked, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { elementSize } from '@cpangular/rxjs';

import { combineLatest, distinctUntilChanged, map, shareReplay } from 'rxjs';

function findElementsWithId(elm: HTMLElement): HTMLElement[] {
  return (Array.from(elm.children) as HTMLElement[]).filter((child) => child.hasAttribute('id'));
}

@Component({
  selector: 'ideal-multi-page-scroll-layout',
  standalone: true,
  imports: [JsonPipe, AsyncPipe],
  templateUrl: './multi-page-scroll-layout.component.html',
  styleUrl: './multi-page-scroll-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiPageScrollLayoutComponent {
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _routerFragment = toSignal(
    this._route.fragment.pipe(
      map((f) => f || ''),
      distinctUntilChanged(),
      takeUntilDestroyed(),
    ),
    { requireSync: true },
  );
  private readonly _cdkScrollable = inject(CdkScrollable);
  private readonly _cdkScrollableSize$ = elementSize(this._cdkScrollable.getElementRef().nativeElement).pipe(
    takeUntilDestroyed(),
    shareReplay(1),
  );
  private readonly _cdkScrollableScroll$ = this._cdkScrollable.elementScrolled();
  private readonly _cdkScrollableTop$ = this._cdkScrollableSize$.pipe(
    map((s) => this._cdkScrollable.getElementRef().nativeElement.getBoundingClientRect().top),
  );

  private readonly _cdkScrollableOffset$ = combineLatest([this._cdkScrollableScroll$, this._cdkScrollableTop$]).pipe(
    map(([_, top]) => {
      return top + this._cdkScrollable.measureScrollOffset('top') + 32;
    }),
  );

  protected readonly cdkScrollableOffset = toSignal(this._cdkScrollableOffset$, { initialValue: 0 });

  private readonly _contentElementRef = viewChild.required<ElementRef<HTMLElement>, ElementRef>('content', { read: ElementRef });
  private readonly _contentElement$ = toObservable(this._contentElementRef).pipe(map((r) => r.nativeElement));
  private readonly _contentChildren$ = this._contentElement$.pipe(map(findElementsWithId));
  private readonly _lastContentChild$ = this._contentChildren$.pipe(map((c) => c[c.length - 1]));

  private readonly _spacerSize$ = combineLatest([this._lastContentChild$, this._cdkScrollableSize$]).pipe(
    map(([last, scrollable]) => {
      return Math.max(0, scrollable.height - last.offsetHeight);
    }),
  );
  protected spacerSize = toSignal(this._spacerSize$, { initialValue: 0 });

  protected readonly scrollViewPage = toSignal(
    combineLatest([
      this._cdkScrollableOffset$,
      this._contentChildren$.pipe(map((c) => c.sort((a, b) => Math.min(Math.max(b.offsetTop - a.offsetTop, -1), 1)))),
    ]).pipe(
      map(([offset, pages]) => {
        const page = pages.find((p) => p.offsetTop <= offset);
        return page?.id ?? '';
      }),
      distinctUntilChanged(),
    ),
    { initialValue: '' },
  );

  constructor() {
    let first = true;
    effect(() => {
      const routerFragment = this._routerFragment();
      const scrollViewPage = untracked(this.scrollViewPage);
      if (routerFragment !== scrollViewPage) {
        const elm = routerFragment ? untracked(this._contentElementRef).nativeElement.querySelector(`#${routerFragment}`) : null;
        const top = elm?.offsetTop ?? 0;
        if (first) {
          setTimeout(() => {
            this._cdkScrollable.scrollTo({ top });
          }, 0);
        } else {
          this._cdkScrollable.scrollTo({ top, behavior: 'smooth' });
        }
      }
    });

    effect(() => {
      const scrollViewPage = this.scrollViewPage();
      if (first) {
        first = false;
        return;
      }
      if (this._route.snapshot.fragment !== scrollViewPage) {
        this._router.navigate([], { fragment: scrollViewPage || undefined });
      }
    });
  }
}
