import { Directive, inject, input, TemplateRef } from '@angular/core';
import { GridCellTemplateContext } from '../types/GridCellTemplateContext';

@Directive({
  selector: '[gridCell]',
  standalone: true,
})
export class GridCellDirective<TData> {
  public readonly template = inject(TemplateRef<GridCellTemplateContext<TData>>);

  static ngTemplateContextGuard<T>(_: GridCellDirective<T>, ctx: any): ctx is GridCellTemplateContext<T> {
    return true;
  }
}
