import { inject, InjectionToken, Signal } from '@angular/core';
import { DefaultFormOptions } from './DefaultFormOptions';
import { FormGroup, FormSubmittedEvent, FormResetEvent, ValueChangeEvent } from '@angular/forms';

export interface FormOptions<TForm extends FormGroup = FormGroup> {
  disabled: Signal<boolean>;
  onFormSubmitted: (event: FormSubmittedEvent, form: TForm) => void;
  onFormValueChange: <T>(event: ValueChangeEvent<T>, form: TForm) => void;
  onFormReset: (event: FormResetEvent, form: TForm) => void;
}

export const FormOptions = new InjectionToken<Partial<FormOptions>>('FormOptions', {
  factory: () => inject(DefaultFormOptions),
});
