import { PagedSearchModel } from '../types';
import { serializeFilter } from './serializeFilter';
import { setPagedSearchCache } from './setPagedSearchCache';
import { updatePagedSearchCacheEntry } from './updatePagedSearchCacheEntry';

export function initPagedSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: PagedSearchModel<TData> },
): ReturnType<typeof setPagedSearchCache<TData>> | null;
export function initPagedSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: PagedSearchModel<TData> },
  reset: true,
): ReturnType<typeof setPagedSearchCache<TData>>;
export function initPagedSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: PagedSearchModel<TData> },
  reset: false,
): ReturnType<typeof setPagedSearchCache<TData>> | null;
export function initPagedSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: PagedSearchModel<TData> },
  reset = false,
) {
  const key = serializeFilter(filter);
  if (key in cache && !reset) {
    return null;
  }

  const search = {
    key,
    created: 0,
    updated: 0,
    totalResults: -1,
    results: [],
  };

  return updatePagedSearchCacheEntry(search, cache);
}
