import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorCardComponent } from '../../components/error-card/error-card.component';
import { ErrorJsonComponent } from '../../components/error-json/error-json.component';
import { ErrorMessageComponent } from '../../components/error-message/error-message.component';
import { ErrorTitleComponent } from '../../components/error-title/error-title.component';
import { ErrorHandlerBaseComponent } from '../error-handler-base.component';

@Component({
  selector: 'ideal-unknown-error-handler',
  standalone: true,
  imports: [ErrorCardComponent, ErrorTitleComponent, ErrorMessageComponent, ErrorJsonComponent],
  templateUrl: './unknown-error-handler.component.html',
  styleUrls: ['../error-handler-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnknownErrorHandlerComponent extends ErrorHandlerBaseComponent {}
