import { inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { patchState, SignalStoreFeature, signalStoreFeature, withHooks, withMethods, withState, WritableStateSource } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import type { SignalStoreFeatureResult, StateSignals } from '@ngrx/signals/src/signal-store-models';
import type { Prettify } from '@ngrx/signals/src/ts-helpers';
import { minimumTime } from 'common-util';
import { filter, merge, Observable, pipe, share, Subject, Subscription, switchMap, tap } from 'rxjs';
import { RequestEvent } from './RequestEvent';
import { RequestMethod } from './RequestMethod';
import { RequestOptions } from './RequestOptions';
import { RequestStatus } from './RequestStatus';
import { RequestStatusEvent } from './RequestStatusEvent';
import { DefaultRequestOptions } from './tokens/DefaultRequestOptions';

declare type Mutable<T extends object> = {
  -readonly [K in keyof T]: T[K];
};

type WithRequestStateDictionary<TName extends string> = {
  [K in `${TName}Status`]: RequestStatus;
};

type WithRequestMethodDictionary<TName extends string, TArgs = unknown> = {
  [K in TName]: RequestMethod<TArgs>;
};

type WithRequestLoadFunction<TArgs, TResult = any> = (args: TArgs, eventEmitter: Subject<RequestEvent>) => Observable<TResult>;

export function withRequest<TName extends string, Input extends SignalStoreFeatureResult, TArgs = void>(
  name: TName,
  methodsFactory: (
    store: Prettify<StateSignals<Input['state']> & Input['computed'] & Input['methods'] & WritableStateSource<Prettify<Input['state']>>>,
  ) => WithRequestLoadFunction<TArgs>,
  options: Partial<RequestOptions> | (() => Partial<RequestOptions>) = {},
): SignalStoreFeature<
  Input,
  {
    state: WithRequestStateDictionary<TName>;
    computed: {};
    methods: WithRequestMethodDictionary<TName, TArgs>;
  }
> {
  return signalStoreFeature(
    withState({
      [`${name}Status`]: RequestStatus.INITIALIZING,
    } as WithRequestStateDictionary<TName>),
    withMethods((store) => {
      const opts: RequestOptions = {
        ...inject(DefaultRequestOptions),
        ...inject(RequestOptions),
        ...(typeof options === 'function' ? options() : options),
      };

      const request = methodsFactory(store as any);

      const status = signal(RequestStatus.INITIALIZING);
      const error = signal(null);

      const evtEmit = new Subject<RequestEvent>();
      const statusEvents = evtEmit.pipe(
        filter((v): v is RequestStatusEvent => v instanceof RequestStatusEvent),
        minimumTime(opts.minimumStatusChangeTime, (n, o) => !o || o.status === RequestStatus.INITIALIZING),
        tap((v) => {
          patchState(store, {
            [`${name}Status`]: v.status,
          } as { [K in `${TName}Status`]: RequestStatus });
          status.set(v.status);
          error.set(v.error);
        }),
        share(),
      );
      const nonStatusEvents = evtEmit.pipe(filter((v) => !(v instanceof RequestStatusEvent)));
      const events = merge(statusEvents, nonStatusEvents).pipe(takeUntilDestroyed(), share());

      const requestMethod: Mutable<RequestMethod<TArgs>> = rxMethod<TArgs>(
        pipe(
          switchMap((args: TArgs) => {
            evtEmit.next(new RequestStatusEvent(RequestStatus.FETCHING, null));
            return request(args, evtEmit).pipe(
              tapResponse({
                next: () => {
                  evtEmit.next(new RequestStatusEvent(RequestStatus.READY, null));
                },
                error: (error) => {
                  evtEmit.next(new RequestStatusEvent(RequestStatus.ERROR, error));
                },
              }),
            );
          }),
        ),
      ) as RequestMethod<TArgs>;

      requestMethod.events = events;
      requestMethod.status = status;
      requestMethod.error = error;

      return {
        [name]: requestMethod,
      } as WithRequestMethodDictionary<TName, TArgs>;
    }),
    withHooks((store) => {
      let sub: Subscription;
      return {
        onInit() {
          sub = store[name].events.subscribe();
        },
        onDestroy() {
          sub?.unsubscribe();
        },
      };
    }),
  );
}
