import { InjectionToken, signal } from '@angular/core';
import { FormOptions } from './FormOptions';

export const DefaultFormOptions = new InjectionToken<FormOptions>('DefaultFormOptions', {
  providedIn: 'root',
  factory: () => {
    const disabled = signal(false).asReadonly();
    const onFormSubmitted = () => {};
    const onFormReset = () => {};
    const onFormValueChange = () => {};
    return { disabled, onFormSubmitted, onFormReset, onFormValueChange };
  },
});
