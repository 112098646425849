import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageNotFoundErrorDetails } from 'error-data';
import { ErrorCardComponent, ErrorJsonComponent, ErrorMessageComponent, ErrorTitleComponent } from '../../components';
import { ErrorHandlerBaseComponent } from '../error-handler-base.component';

@Component({
  selector: 'ideal-page-not-found-error-handler',
  standalone: true,
  imports: [ErrorCardComponent, ErrorTitleComponent, ErrorMessageComponent, ErrorJsonComponent],
  templateUrl: './page-not-found-error-handler.component.html',
  styleUrls: ['../error-handler-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundErrorHandlerComponent extends ErrorHandlerBaseComponent<PageNotFoundErrorDetails> {}
