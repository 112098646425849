import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ErrorService } from 'error-data';

@Component({
  selector: 'ideal-error',
  standalone: true,
  imports: [NgComponentOutlet],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  public readonly error = input.required<any>();

  private readonly _errorService = inject(ErrorService);
  protected readonly errorHandlerComponent = computed(() => this._errorService.getErrorHandler(this.error()).component);
}
