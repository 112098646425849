import { PagedSearchModel } from '../types';

export function updatePagedSearchCacheEntry<TData>(search: PagedSearchModel<TData>, cache: { [key: string]: PagedSearchModel<TData> }) {
  return {
    _searchCache: {
      ...cache,
      [search.key]: search,
    },
  };
}
