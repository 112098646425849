import { computed } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { isEqual } from 'lodash';
import { Observable, tap } from 'rxjs';
import { withRequest } from '../request-status';
import { ModelState } from './ModelState';

export function withModel<TModel>(initialState: TModel) {
  return signalStoreFeature(
    withState<ModelState<TModel>>({ model: initialState, modelSaved: initialState }),
    withMethods(({ model, modelSaved }) => ({
      isEqual: (value: any) => isEqual(model(), value),
    })),
    withComputed(({ model, modelSaved }) => ({
      isDirty: computed(() => !isEqual(model(), modelSaved())),
    })),
  );
}

export function setModel<TModel>(model: TModel) {
  return { model, modelSaved: model };
}

export function updateModel<TModel>(model: TModel) {
  return { model };
}

export function restoreModel() {
  return (state: ModelState<any>) => ({ model: state.modelSaved });
}

export function saveModel() {
  return (state: ModelState<any>) => ({ modelSaved: state.model });
}

export function withModelLoader<TModel>(loadRequest: Observable<TModel> | (() => Observable<TModel>), opts?: { autoLoad?: boolean }) {
  opts ??= {};
  return signalStoreFeature(
    withRequest('loadModel', (store) => {
      if (typeof loadRequest === 'function') {
        loadRequest = loadRequest();
      }
      return () => (loadRequest as Observable<TModel>).pipe(tap((model) => patchState(store, setModel(model))));
    }),
    withHooks((store) => {
      return {
        onInit() {
          if (opts.autoLoad) {
            store.loadModel();
          }
        },
      };
    }),
  );
}
