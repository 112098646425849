import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractDialogComponent } from 'common-ui';
import { ErrorComponent } from '../error/error.component';
import { ErrorDialogConfigData } from './ErrorDialogConfigData';

@Component({
  selector: 'ideal-error-dialog',
  standalone: true,
  imports: [ErrorComponent],
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent extends AbstractDialogComponent<ErrorDialogConfigData> {
  protected readonly error = this.dialogData.error;
}
