import { inject, InjectionToken } from '@angular/core';
import { DefaultRequestOptions } from './tokens/DefaultRequestOptions';

export interface RequestOptions {
  minimumStatusChangeTime: number;
}

export const RequestOptions = new InjectionToken<Partial<RequestOptions>>('RequestOptions', {
  factory: () => inject(DefaultRequestOptions),
});
