import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ideal-one-column-layout',
  standalone: true,
  imports: [],
  templateUrl: './one-column-layout.component.html',
  styleUrl: './one-column-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneColumnLayoutComponent {}
