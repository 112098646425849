import { computed, Directive, inject, input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ErrorService } from 'error-data';
import { map } from 'rxjs';

function getUrl() {
  return typeof window !== undefined ? window.location.href : '';
}

@Directive()
export abstract class ErrorHandlerBaseComponent<TError = any> {
  private readonly router = inject(Router);
  protected readonly errorService = inject(ErrorService);
  protected readonly url = toSignal(this.router.events.pipe(map(() => getUrl())), { initialValue: getUrl() });
  protected readonly errorFromService = this.errorService.error as Signal<TError>;
  protected readonly errorFromInput = input<TError | undefined>(undefined, { alias: 'error' });
  protected readonly error = computed(() => this.errorFromInput() ?? this.errorFromService());
}
