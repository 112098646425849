import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MarkdownComponent } from 'common-ui';

@Component({
  selector: 'ideal-error-message',
  standalone: true,
  imports: [MarkdownComponent],
  templateUrl: './error-message.component.html',
  styleUrl: './error-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorMessageComponent {}
