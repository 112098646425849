import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { NavigationErrorDetails } from 'error-data';
import { ErrorCardComponent } from '../../components/error-card/error-card.component';
import { ErrorCauseComponent } from '../../components/error-cause/error-cause.component';
import { ErrorJsonComponent } from '../../components/error-json/error-json.component';
import { ErrorMessageComponent } from '../../components/error-message/error-message.component';
import { ErrorTitleComponent } from '../../components/error-title/error-title.component';
import { ErrorHandlerBaseComponent } from '../error-handler-base.component';

@Component({
  selector: 'ideal-navigation-error-handler',
  standalone: true,
  imports: [ErrorCardComponent, ErrorTitleComponent, ErrorMessageComponent, ErrorJsonComponent, NgComponentOutlet, ErrorCauseComponent],
  templateUrl: './navigation-error-handler.component.html',
  styleUrls: ['../error-handler-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationErrorHandlerComponent extends ErrorHandlerBaseComponent<NavigationErrorDetails> {
  protected cause = computed(() => this.error().cause);
  protected causeHandlerComponent = computed(() => this.errorService.getErrorHandler(this.cause()).component);
}
