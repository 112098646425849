import { SearchModel } from '../types';

export function updateSearchCacheEntry<TData>(search: SearchModel<TData>, cache: { [key: string]: SearchModel<TData> }) {
  return {
    _searchCache: {
      ...cache,
      [search.key]: search,
    },
  };
}
